<template>
  <div>
    <b-card>
      <b-overlay :show="overlayShow">
        <div class="row no-print">
          <div class="col-md-12">
            <div class="container">
              <b-input-group>
                <b-form-select
                  ref="groupSelector"
                  v-model="group_id"
                  class="plan-filter-select-input"
                  :options="carsGroups"
                />
                <b-form-datepicker
                  v-model="filterForm.date_start"
                  type="date"
                  start-weekday="1"
                  :state="validDates"
                  :date-format-options="{year: 'numeric', month: 'numeric', day: 'numeric'}"
                />
                <b-form-datepicker
                  v-model="filterForm.date_end"
                  type="date"
                  start-weekday="1"
                  :state="validDates"
                  :date-format-options="{year: 'numeric', month: 'numeric', day: 'numeric'}"
                />
                <b-input-group-append>
                  <b-button
                    variant="primary"
                    @click="fetchPlan"
                  >
                    Показать
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </div>
          </div>
        </div>
        <div class="table-responsive print-land">
          <div class="d-flex mt-1 small align-items-center justify-content-between">
            <div
              class="align-middle"
            >Свободно</div>
            <div
              v-for="item in stats"
              :key="item.date"
            >
              <div><strong>{{ item.date }}</strong></div>
              <div class="text-center">{{ item.day }} / {{ item.night }}</div>
            </div>
          </div>
          <table class="mt-1 table table-bordered table-sm">
            <tr class="d-table-row border-bottom">
              <th>
                Дата
              </th>
              <th
                v-for="car in carsByGroup"
                :key="car.id"
                class="text-center"
              >
                <div class="font-weight-bolder">
                  {{ car.number }}
                </div>
                <div class="small">
                  {{ car.vin }}
                </div>
              </th>
            </tr>
            <template v-for="{day, night, date, week_day} in tableData">
              <tr :key="date + ' 1-row' + group_id">
                <td
                  rowspan="2"
                  @mouseover="onDateMouseOverHandler"
                  @mouseleave="onDateMouseLeaveHandler"
                >
                  <span class="font-weight-bolder">{{ date | dateFormat(true, false) }}</span>
                  <br>
                  <small>{{ week_day }}</small>
                  <div class="print-buttons">
                    <b-button
                      variant="outline-primary"
                      @click="onPrintClickHandler(day.filter(item => item.plan !== undefined))"
                    >
                      <feather-icon icon="PrinterIcon" />
                    </b-button>
                    <b-button
                      variant="outline-primary"
                      @click="onPrintClickHandler(night.filter(item => item.plan !== undefined), false)"
                    >
                      <feather-icon icon="PrinterIcon" />
                    </b-button>
                  </div>
                </td>
                <td
                  v-for="(item, index) in day"
                  :key="index + 'day'"
                  class="hover"
                  @click="planItemClickHandler(item, date, 'day')"
                >
                  <car-plan-item :plan="item.plan" />
                </td>
              </tr>
              <tr :key="date + ' 2-row' + group_id">
                <td
                  v-for="(item, index) in night"
                  :key="index + 'night'"
                  class="bg-light hover"
                  @click="planItemClickHandler(item, date, 'night')"
                >
                  <car-plan-item :plan="item.plan" />
                </td>
              </tr>
            </template>
          </table>
        </div>
      </b-overlay>
    </b-card>
    <b-modal
      ref="plan-form-modal"
      :title="modalTitle"
      hide-footer
    >
      <car-plan-form
        :plan="modalProps.plan"
        :car="modalProps.car"
        :is_day="modalProps.type === 'day'"
        :date="modalProps.date"
        @saved="planSavedHandler"
        @deleted="planDeletedHandler"
        @cancel="$refs['plan-form-modal'].hide()"
      />
    </b-modal>
    <b-modal
      ref="plan-print-modal"
      title="Менеджер актов"
      size="lg"
      :hide-footer="true"
    >
      <car-plan-to-acts
        :items="carPlanPrintData.items"
        :is-day="carPlanPrintData.isDay"
        :group="selectedGroup"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BFormDatepicker,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BModal,
  BOverlay,
} from 'bootstrap-vue'
import moment from 'moment'
import CarPlanItem from './components/CarPlanItem.vue'
import CarPlanForm from './components/CarPlanForm.vue'
import CarPlanToActs from './components/car-plan-to-acts/CarPlanToActs.vue'

const weekdayLabels = {
  1: 'Понедельник',
  2: 'Вторник',
  3: 'Среда',
  4: 'Четверг',
  5: 'Пятница',
  6: 'Суббота',
  7: 'Воскресенье',
}

export default {
  components: {
    CarPlanToActs,
    BCard,
    BModal,
    BOverlay,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect,
    BFormDatepicker,
    CarPlanItem,
    CarPlanForm,
  },
  data() {
    return {
      date_range: {
        start: null,
        end: null,
      },
      filterForm: {
        date_start: moment()
          .format('YYYY-MM-DD'),
        date_end: moment()
          .add(7, 'days')
          .format('YYYY-MM-DD'),
      },
      group_id: null,
      loading_plan: false,
      loading_cars: false,
      loading_cars_groups: false,
      plan: [],
      cars: [],
      cars_groups: [],
      showPlanForm: false,
      planFormData: null,
      carPlanPrintData: {
        isDay: true,
        items: [],
      },
      modalProps: {
        type: 'day',
        date: null,
        plan: null,
        car: null,
      },
    }
  },
  computed: {
    datesList() {
      const dates = []
      const start = moment(this.date_range.start)
      const end = moment(this.date_range.end)
      while (start.isSameOrBefore(end)) {
        dates.push({
          date: start.format('YYYY-MM-DD'),
          week_day: weekdayLabels[start.isoWeekday()],
        })
        start.add(1, 'day')
      }
      return dates
    },
    carsByGroup() {
      return this.cars.filter(item => this.group_id === item.group_id)
    },
    tableData() {
      return this.datesList.map(item => ({
        ...item,
        day: this.carsByGroup.map(c => ({
          plan: this.plan.filter(p => p.date === item.date && p.car_id === c.id && p.is_day)?.pop(),
          car: c,
        })),
        night: this.carsByGroup.map(c => ({
          plan: this.plan.filter(p => p.date === item.date && p.car_id === c.id && !p.is_day)?.pop(),
          car: c,
        })),
      }))
    },
    stats() {
      return this.tableData.map(item => ({
        ...item,
        day: this.carsByGroup.length - item.day.filter(p => p.plan !== undefined).length,
        night: this.carsByGroup.length - item.night.filter(p => p.plan !== undefined).length,
      }))
    },
    overlayShow() {
      return !!this.loading_plan || !!this.loading_cars || !!this.loading_cars_groups
    },
    validDates() {
      return moment(this.filterForm.date_end)
        .isSameOrAfter(this.filterForm.date_start)
    },
    carsGroups() {
      return this.cars_groups.map(item => ({
        text: item.title,
        value: item.id,
      }))
    },
    modalTitle() {
      return `${this.modalProps.date} ${this.modalProps.type === 'day' ? 'дневная' : 'ночная'} смена`
    },
    selectedGroup() {
      return this.cars_groups.filter(group => group.id === this.group_id)?.pop()
    },
  },
  watch: {
    loading_cars_groups(val) {
      if (!val) {
        this.group_id = this.cars_groups.length ? this.cars_groups[0].id : null
      }
    },

  },
  mounted() {
    this.fetchCarsGroups()
    this.fetchCars()
    this.fetchPlan()
  },
  methods: {
    updateDateRange() {
      this.date_range.start = this.filterForm.date_start
      this.date_range.end = this.filterForm.date_end
    },
    async fetchPlan() {
      this.updateDateRange()
      this.loading_plan = true
      this.plan = []
      let page = 1
      let pages = 1
      for (; pages >= page;) {
        const params = {
          page,
          page_size: 100,
          populate: 'driver',
          filter: {
            date__gte: this.date_range.start,
            date__lte: this.date_range.end,
          },
        }
        // eslint-disable-next-line no-await-in-loop
        const { data } = await this.$axios.get('/cars/plans', { params })
        this.plan = [...this.plan, ...data.data]
        pages = data.meta.last_page
        page += 1
      }
      this.group_id = this.$refs.groupSelector.value
      this.loading_plan = false
    },
    async fetchCars() {
      this.loading_cars = true
      this.cars = []
      let page = 1
      let pages = 1
      for (; pages >= page;) {
        const params = {
          page,
          page_size: 100,
          filter: {
            hide_crm: 0,
          },
          sort: 'number',
        }
        // eslint-disable-next-line no-await-in-loop
        const { data } = await this.$axios('/cars', { params })
        pages = data.meta.last_page
        this.cars = [...this.cars, ...data.data]
        page += 1
      }
      this.loading_cars = false
    },
    async fetchCarsGroups() {
      this.loading_cars_groups = true
      this.cars_groups = []
      let page = 1
      let pages = 1
      for (; pages >= page;) {
        const params = {
          page,
          page_size: 100,
        }
        // eslint-disable-next-line no-await-in-loop
        const { data } = await this.$axios('/cars/groups', { params })
        pages = data.meta.last_page
        this.cars_groups = [...this.cars_groups, ...data.data]
        page += 1
      }
      this.loading_cars_groups = false
    },
    planItemClickHandler({ plan, car }, date, type) {
      this.modalProps.plan = plan
      this.modalProps.car = car
      this.modalProps.date = date
      this.modalProps.type = type
      this.planFormData = { ...plan }
      this.$refs['plan-form-modal'].show()
    },
    // eslint-disable-next-line consistent-return
    planSavedHandler(plan) {
      let founded = false
      this.plan = this.plan.map(item => {
        if (plan.id === item.id) {
          founded = true
          return plan
        }
        return item
      })
      if (founded) {
        return this.$refs['plan-form-modal'].hide()
      }
      let insertAfter = 0
      const planMoment = moment(plan.date)
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.plan.length; i++) {
        if (planMoment.isAfter(this.plan[i].date)) {
          insertAfter = i
        }
      }
      this.plan.splice(insertAfter, 0, plan)
      this.$refs['plan-form-modal'].hide()
    },
    planDeletedHandler() {
      this.plan = this.plan.filter(item => item.id !== this.modalProps.plan.id)
      this.$refs['plan-form-modal'].hide()
    },
    onDateMouseOverHandler(event) {
      if (event.target.nodeName !== 'TD') {
        return
      }
      const printButtons = event.target.querySelector('div.print-buttons')
      printButtons.classList.add('d-flex')
      setTimeout(() => {
        event.target.parentNode.classList.add('show-print-buttons')
        printButtons.classList.remove('d-flex')
      }, 1)
    },
    onDateMouseLeaveHandler(event) {
      event.target.parentNode.classList.remove('show-print-buttons')
    },
    onPrintClickHandler(items, isDay = true) {
      if (items.length === 0) {
        this.$bvToast.toast('Нет данных для создания и печати актов', { variant: 'warning', title: 'Внимание' })
      } else {
        this.carPlanPrintData.items = items
        this.carPlanPrintData.isDay = isDay
        this.$refs['plan-print-modal'].show()
      }
    },
  },
}
</script>
<style>
.plan-filter-select-input {
  height: 40px;
}

td.hover {
  cursor: pointer;
  transition: all .3s ease;
}

td.hover > div {
  min-height: 70px;
}

td.hover:hover {
  background: var(--primary) !important;
  color: var(--light) !important;
}
tr td:first-child{
  position: relative;
}
tr td>.print-buttons{
  display: none;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: space-around;
  transition: all .3s;
}
tr.show-print-buttons td>.print-buttons{
  display: flex;
  right: 20px;
  opacity: 1;
}
</style>
